import React from 'react';

const View = () => {
    return (
        <div>
        
        </div>
    )
};

export default View;
